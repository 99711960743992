<template>
  <div class="settings-container">
    <div class="settings-header">
      <slot name="header" class="settings-title"></slot>
    </div>
    <div class="card-body settings-body">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsContainer",
};
</script>

<style lang="scss" scoped>
.settings-header {
  text-align: left;
  color: $primary;
  margin: $item-margin;
  font-size: 1.2em;
}
.settings-container {
  background-color: $grey-300;
  margin: $item-margin;
  border-radius: $border-radius;
  width: 100%;
}
.settings-body {
  background-color: $grey-200;
}
.settings-title {
  margin: $item-margin;
}
</style>
