<template>
  <div class="profile-settings">
    <SettingsContainer>
      <template v-slot:header>
        <p class="m-0">Change Public Info</p>
      </template>
      <template v-slot:body>
        <div class="settings-grid">
          <div class="group">
            <p class="settings-text">Your current Profile Picture is:</p>
            <img
              :src="this.$store.state.userData.thumbnail"
              class="small-image"
              alt="profile image" />
          </div>
          <button
            @click="showPictureChangePopup = true"
            class="button button-dark">
            Change
          </button>
          <p class="settings-text">
            Your current Alias is:
            <span class="info-text">{{
              this.$store.state.userData.alias
            }}</span>
          </p>
          <button @click="changeItem('alias')" class="button button-dark">
            Change
          </button>
          <p class="settings-text">
            Your current Bio is:
            <span class="info-text"
              >{{ this.$store.state.userData.bio?.slice(0, 50) }}...</span
            >
          </p>
          <button @click="changeItem('bio')" class="button button-dark">
            Change
          </button>
          <p class="settings-text">Update your social links</p>
          <button class="button button-info" @click="showLinksPopup = true">
            update
          </button>
        </div>
      </template>
    </SettingsContainer>
    <SettingsContainer>
      <template v-slot:header> <p class="m-0">Change Private Info</p></template>
      <template v-slot:body>
        <div class="settings-grid">
          <div class="settings-text">
            <p>
              Your username is:
              <span class="info-text">{{
                $store.state.userData.username
              }}</span>
            </p>
          </div>
          <button
            class="button button-dark"
            @click="
              $notify({
                title: 'sorry',
                body: 'this isnt finished yet',
                type: 'warning',
              })
            ">
            Change
          </button>
          <div class="settings-text">
            <p>Change Your Password</p>
          </div>
          <button class="button button-dark" @click="changeItem('password')">
            Change
          </button>
          <div class="settings-text">
            <p>Change Your email</p>
          </div>
          <button
            class="button button-dark"
            @click="
              $notify({
                title: 'sorry',
                body: 'this isnt finished yet',
                type: 'warning',
              })
            ">
            Change
          </button>
        </div>
      </template>
    </SettingsContainer>
    <ModalPopup :show="showPopup" @close="closePopup()">
      <template v-slot:body>
        <input-field
          v-if="changingPassword"
          v-model="currentPassword"
          ref="input1"
          :data="{ label: 'Old Password', type: 'password' }"></input-field>
        <input-field
          type="text"
          v-model="itemToChange"
          ref="input2"
          :data="{
            label: label,
            type: changingPassword ? 'password' : 'text',
          }"></input-field>
      </template>
      <template v-slot:buttons>
        <button class="button button-primary" @click="changeClickFn()">
          Change
        </button>
        <button class="button button-dark" @click="closePopup()">
          Cancel
        </button></template
      >
    </ModalPopup>
    <ModalPopup :show="showPictureChangePopup" @close="closePopup()">
      <template v-slot:body>
        <picture-input-field
          v-model="newImage"
          :keepSquare="true"></picture-input-field>
      </template>
      <template v-slot:buttons>
        <button class="button button-primary" @click="updatePicture()">
          Change
        </button>
        <button class="button button-dark" @click="closePopup()">
          Cancel
        </button></template
      >
    </ModalPopup>
    <ModalPopup :show="showLinksPopup" @close="closePopup()">
      <template v-slot:body>
        <div class="social-links">
          <p class="info-text small-info">
            Add a link to your profile, page, etc. Leave blank to remove.
          </p>
          <div
            v-for="key in Object.keys(valid_links)"
            :key="key"
            class="icon-input-combo">
            <social-icon class="small-icon" :type="key"></social-icon>
            <input-field
              v-model="valid_links[key]"
              :data="{ label: 'add your ' + key }"></input-field>
          </div>
        </div>
      </template>
      <template v-slot:buttons>
        <button class="button button-primary" @click="updateLinks()">
          Change
        </button>
        <button class="button button-dark" @click="closePopup()">
          Cancel
        </button></template
      >
    </ModalPopup>
  </div>
</template>

<script>
import SettingsContainer from "@/components/basic/SettingsContainer.vue";
import ModalPopup from "@/components/basic/ModalPopup.vue";
import axios from "axios";
import PictureInputField from "./PictureInputField.vue";
import InputField from "./InputField.vue";
import SocialIcon from "./basic/SocialIcon.vue";
export default {
  name: "ProfileSettings",
  components: {
    SettingsContainer,
    ModalPopup,
    PictureInputField,
    InputField,
    SocialIcon,
  },
  methods: {
    changeItem(type) {
      this.showPopup = true;
      this.label = type;
      if (type === "password") {
        this.changingPassword = true;
        this.label = "New Password";
        this.itemToChange = "";
        this.changeClickFn = () => {
          this.$store.commit("setLoading", true);
          axios
            .post("/api/v1/users/set_password/", {
              current_password: this.currentPassword,
              new_password: this.itemToChange,
            })
            .then((res) => {
              if (res.status == 204) {
                this.$store.commit("setLoading", false);
                this.closePopup();
                this.$notify({
                  title: "Success!",
                  body: "Your password was changed!",
                  type: "success",
                });
              }
            })
            .catch((err) => {
              if (err.response && err.response.data) {
                Object.keys(err.response.data).forEach((key) => {
                  let input = this.$refs.input1;
                  if (key == "new_password") {
                    input = this.$refs.input2;
                  }
                  err.response.data[key].forEach((error) => {
                    if (input.shownErrors.includes(error)) {
                      input.shake();
                    } else {
                      input.shownErrors.push(error);
                    }
                  });
                });
              }
            });
        };
      } else {
        this.itemToChange = this.$store.state.userData[type];
        this.changeClickFn = () => {
          this.sendChange(this.itemToChange, type);
        };
      }
    },
    changeClickFn() {
      return;
    },
    closePopup() {
      this.showPopup = false;
      this.showPictureChangePopup = false;
      this.changingPassword = false;
      this.changingPicture = false;
      this.showLinksPopup = false;
    },
    sendChange(item, dataName) {
      let data = {};
      data[dataName] = item;
      axios.patch("/api/v1/users/me/", data).then((res) => {
        console.log(data);
        this.$store.commit("setUserData", res.data);
        this.showPopup = false;
      });
    },
    updatePicture() {
      let data = new FormData();
      data.append("profile_image", this.newImage);
      axios.patch("/api/v1/users/me/", data).then((res) => {
        this.$store.commit("setUserData", res.data);
        this.showPictureChangePopup = false;
      });
    },
    updateLinks() {
      let data = new FormData();

      data.append("social_links", JSON.stringify(this.valid_links));
      axios.patch("/api/v1/users/me/", data).then((res) => {
        this.$store.commit("setUserData", res.data);
        this.showLinksPopup = false;
      });
    },
  },
  mounted() {
    Object.keys(this.$store.state.userData.social_links).forEach((key) => {
      this.valid_links[key] = this.$store.state.userData.social_links[key];
    });
  },
  data() {
    return {
      showPopup: false,
      showPictureChangePopup: false,
      showLinksPopup: false,
      itemToChange: "",
      newFirstName: this.$store.state.userData.first_name,
      newLastName: this.$store.state.userData.last_name,
      newImage: {},
      changingPassword: false,
      changingPicture: false,
      currentPassword: "",
      label: "",
      valid_links: {
        facebook: "",
        reddit: "",
        linkedin: "",
        whatsapp: "",
        pinterest: "",
        instagram: "",
        medium: "",
        youtube: "",
        twitter: "",
        stackoverflow: "",
        github: "",
        email: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-settings {
  width: 100%;
  display: flex;
}
.settings-grid {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-row-gap: 10px;
  align-items: center;
  justify-items: left;
  grid-auto-rows: 4rem;
}

.small-image {
  width: 50px;
}
.group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}
.icon-input-combo {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.2rem;
  width: 95%;
}
.social-links {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.small-icon {
  width: 2.2rem;
  height: 2.2rem;
}
.small-info {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
}
@media screen and (max-width: $switch-width) {
  .profile-settings {
    flex-direction: column;
  }
}
</style>
