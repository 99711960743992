<template>
  <div class="page-settings">
    <GeneralSettings v-if="pageType === 'general'"> </GeneralSettings>
    <ProfileSettings v-if="pageType === 'profile'"></ProfileSettings>
  </div>
</template>

<script>
import ProfileSettings from "@/components/ProfileSettings.vue";
import GeneralSettings from "@/components/GeneralSettings.vue";
export default {
  name: "Settings",
  components: {
    ProfileSettings,
    GeneralSettings,
  },
  data() {
    return {
      pageType: "general",
    };
  },
  beforeMount() {
    let type = this.$route.params.section;
    console.log(type);
    if (type && type !== "") {
      this.pageType = type;
    }
  },
  mounted() {
    document.title = this.pageType + " settings | icandoathing";
  },
};
</script>

<style lang="scss" scoped></style>
