<template>
  <div class="menu-general">
    <h2>Settings</h2>
    <br />
    <ul>
      <li>
        <router-link to="/settings/profile">View Settings</router-link>
        <p class="subtitle">Change Your View Settings</p>
      </li>
      <li>
        <router-link to="/settings/profile">Profile Settings</router-link>
        <p class="subtitle">Change Your Profile Settings</p>
      </li>
      <li>
        <router-link to="/settings/privacy">Privacy Settings</router-link>
        <p class="subtitle">Change Your Privacy Settings</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "General Settings",
};
</script>

<style lang="scss" scoped></style>
